/* General Styles */
.home-container {
  font-family: 'Roboto', sans-serif;
  color: #4e4e56;
  overflow-x: hidden;
}

/* Full-screen Header styles */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: #594187;
  overflow: hidden;
  z-index: 1000;
  transition: height 0.3s ease, padding 0.3s ease, background-color 0.3s ease;
}
::-webkit-scrollbar {
  display: none;
}

/* Header styles when scrolled */
.header.scrolled {
  height: 80px;
  padding: 10px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #4e3a8a; /* Slightly darker on scroll */
}

/* Logo container styles */
.logo-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease;
}

/* Logo Circle with Bounce Animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.logo-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8dff8;
  overflow: hidden;
  transition: width 0.3s ease, height 0.3s ease;
  animation: bounce 2s infinite;
}

.header.scrolled .logo-circle {
  width: 60px;
  height: 60px;
}

.logo-image {
  width: 80%;
}

.logo-text {
  margin-left: 20px;
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  transition: font-size 0.3s ease;
}

.header.scrolled .logo-text {
  font-size: 1.4rem;
  margin-left: 10px;
}

/* Navigation Menu */
nav {
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1001; /* Ensure nav is above other elements */
}

/* Navigation UL styles */
nav ul {
  list-style: none;
  display: flex;
  font-style: normal;
  gap: 20px;
  padding: 0;
  margin: 0;
}

/* Navigation LI styles */
nav li {
  font-size: 0.9rem;
  font-style: normal;
  color: #ffffff;
}

/* Navigation Links and Contact Button styles */
nav a,
.contact-btn {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  font-style: normal;
}

/* Navigation Active Link Style */
nav a.active {
  color: #ffffff;
  position: relative;
}

nav a.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: #ffffff;
  position: absolute;
  bottom: -2px;
  left: 0;
}

/* Hamburger Menu */
.hamburger-menu {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.hamburger-menu span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #ffffff;
  margin: 5px 0;
}

/* Full Page Menu Styles */
.full-page-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark semi-transparent background */
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1002; /* Ensure it is above the header */
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-100%);
  opacity: 0;
  overflow: hidden; /* Hide overflow for smooth transition */
}

/* Full Page Menu active state */
.full-page-menu.active {
  transform: translateY(0);
  opacity: 1;
  display: block;
}

/* Full Page Menu UL styles */
.full-page-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

/* Full Page Menu LI styles */
.full-page-menu li {
  font-size: 1.5rem;
}

/* Home.css */

.popup {
  position: absolute ; /* Ensures popups are positioned relative to the viewport */
  top: 100%; /* Adjust this based on your header height */
  left: 50%;
  transform: translateX(-50%);
  background-color: #594187;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure popups are above other content */
  text-align: center;
  display: none; /* Initially hidden */
}
/* Show popup only when not scrolled */
.header:not(.scrolled) .popup {
  display: block;
}
.error-message {
  position: fixed; /* Ensures popups are positioned relative to the viewport */
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px); /* Make popups responsive */
  max-width: 400px; /* Limit maximum width */
  background-color: #594187;
  color: #fff;
  border: 1px solid #d8000c;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure popups are above other content */
  text-align: center;
  display: none; /* Initially hidden */
}



/* Header Popup Styles */
.popup {
  top: 10px; /* Position at the top of the viewport */
}

/* Error Popup Styles */
.error-message {
  bottom: 500px; /* Position at the bottom of the viewport */
}

/* Showing the popups */
.popup.show, .error-message.show {
  display: block; /* Show popups when necessary */
}


.header.scrolled .popup {
  display: none; /* Hide popup when scrolled */
}

.header:not(.scrolled) .popup {
  display: block; /* Show popup when not scrolled */
}

/* Full Page Menu Links styles */
.full-page-menu a {
  color: #fbf5f5;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

/* Full Page Menu Links hover effect */
.full-page-menu a:hover {
  color: #ddd;
}

/* Close button styles */
.close-menu-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Close button hover effect */
.close-menu-btn:hover {
  color: #ddd;
}

/* Back button styles */
.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Back button hover effect */
.back-btn:hover {
  color: #ddd;
}

/* Main Section styles */
.home-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px;
  background-color: #f8f9fa;
  margin-top: 100vh;
}



/* Error Message in Main Section */
.home-section .error-message {
  display: none; /* Initially hidden */
}

.home-section .error-message.show {
  display: block; /* Show when error occurs */
}
/* Hero Content styles */
.hero-content {
  max-width: 65%;
}



.hero-content h1,
.hero-content h2 {
  font-size: 2.8rem;
  color: #6a60ad;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  line-height: 1.2;
}

.hero-image {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
margin-bottom: 50px;
}

.hero-image img {
  max-width: 400px;
  height: auto;
  display: block;
}

.currency-symbol {
  color: #6a60ad;
}

.download-section {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.phone-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.download-btn {
  padding: 10px 20px;
  background-color: #6a60ad;
  color: white;
  border: none;
  border-radius: 8px;
}


/* Footer styles */
.footer {
  background-color: #594187;
  text-align: center;
  padding: 5px 0;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 10px;
  font-size: 0.9rem;
}

.footer-links a:hover {
  color: #ddd;
}

.footer-links .contact-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffffff;
  color: #594187;
  border-radius: 5px;
  font-weight: bold;
}

.footer-links .contact-btn:hover {
  background-color: #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
  
  .header.scrolled {
    height: 80px; /* Adjust as needed */
    padding: 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #4e3a8a; 
  }
  .hero-image img {
    max-width: 150px;
    height: auto;
    display: none;
  }
  .header.scrolled .logo-circle {
    margin-left: auto; /* Align to the right */
    margin-left: 10px; /* Add spacing from the right */
  }

  .header.scrolled .logo-circle {
    width: 40px;
    height: 40px;
  }
  .header.scrolled .logo-text {
    font-size: 1rem;
    margin-left: 10px;
  }

  .logo-text {
    font-size: 1.8rem;
    margin-left: 10px;
  }

  .nav {
    display: none; /* Hide navigation on small screens */
  }

  .hamburger-menu {
    display: block; 
    cursor: pointer;
    font-size: 44px;
    color: #ffffff;/* Show hamburger menu on small screens */
  }

  .full-page-menu {
    padding: 0 10px;
    background-color: #000000e1;
    font-size: 20px;
    font: 'Roboto', sans-serif;
  }

  .footer-links {
    flex-direction: column;
  }

  .footer-links a {
    margin-bottom: 10px;
  }
  .nav-list {
    display: none;
  }
   .error-message {
    width: calc(100% - 20px); /* Adjust for smaller screens */
    font-size: 0.9rem;
    padding: 8px;
  }
  .popup {
    width: 90%; /* Adjust width for small screens */
    font-size: 14px; /* Reduce font size for smaller devices */
    top: 120%; /* Adjust vertical position if needed */
  }

}

@media (max-width: 480px) {
  .hero-content h1,
  .hero-content h2 {
    font-size: 2.2rem;
  }

  .logo-circle {
    width: 80px;
    height: 80px;
  }

  .logo-text {
    font-size: 1.5rem;
  }
  
  .error-message {
    width: calc(100% - 10px); /* Adjust further for very small screens */
    font-size: 0.8rem;
    padding: 6px;
  }
  .popup {
    width: 95%; /* Increase width for very small screens */
    font-size: 12px; /* Further reduce font size */
    top: 130%; /* Adjust further if needed */
  }

  .hero-content {
    padding-top: 60px; }
}

.activeMenu li{
  font-size: 1.5rem;
  font-style: normal;
  color: #ffffff;
}
/* Header popup adjustments for large screens */
@media (min-width: 1024px) {
  .popup {
    top: 180px; /* Adjust the position just above the text */
    font-size: 1.2rem; /* Adjust font size for large screens */
    padding: 10px 20px; /* Adjust padding */
  }
}


.reviews-rating {
  text-align: center;
  padding: 20px;
}

.reviews-rating h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.reviews-rating .highlight {
  color: #594187;
}

.reviews-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.review-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.review-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.1);
}


.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.user-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-info h3 {
  font-size: 18px;
  margin: 0;
}

.contest-info {
  background: #594187;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}

.contest-info p {
  margin: 5px 0;
}

.review-text {
  font-size: 14px;
  color: #333;
}

.app-rating {
  margin-top: 20px;
}

.app-rating .stars {
  font-size: 20px;
}

.app-rating .rating {
  font-size: 20px;
  font-weight: bold;
}

.app-rating .ratings-count {
  font-size: 14px;
  color: #888;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .reviews-container {
    flex-direction: column;
    align-items: center;
  }

  .review-card {
    width: 90%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .review-card {
    width: 45%;
  }
}




/* Basic styling for the section */
.quiz-categories {
  background-color: #f9f9f9;
  text-align: center;
  padding-bottom: 60px; /* Adds bottom padding to separate it from other sections and for scrollbar spacing */
}

.quiz-categories h2 {
  font-size: 2.5rem;
  margin-top : 40px;
  margin-bottom: 20px;
  color: #333;
}

/* Styling the categories container to behave like a carousel */
.categories-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  gap: 20px;
  padding: 10px 30px; /* Adds padding from the sides */
  height: 300px;
  scroll-padding-left: 10px; /* Ensures the first item starts fully visible */
  position: relative; /* Positioning to center the scrollbar */
}

/* Custom scrollbar for WebKit browsers */
.categories-container::-webkit-scrollbar {
  height: 3px; /* Smaller scrollbar */
  width: 40%; /* Make the scrollbar width smaller to be centered on the page */
}

.categories-container::-webkit-scrollbar-thumb {
  background: linear-gradient(5deg, #4a44443c, #5e5c5b); /* Gradient thumb */
  border-radius: 10px;
  transition: background 0.3s ease;
}



/* Basic styling for the section */
.quiz-categories {
  background-color: #f9f9f9;
  text-align: center;
  padding-bottom: 60px; /* Adds bottom padding to separate it from other sections and for scrollbar spacing */
  padding-top: 10px;
}

.quiz-categories h2 {
  font-size: 2.5rem;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #594187;
}

/* Styling the categories container to behave like a carousel */
.categories-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  gap: 20px;
  padding: 10px 30px; /* Adds padding from the sides */
  height: 300px;
  scroll-padding-left: 10px; /* Ensures the first item starts fully visible */
  position: relative; /* Positioning to center the scrollbar */
}

/* Custom scrollbar for WebKit browsers */
.categories-container::-webkit-scrollbar {
  height: 3px; /* Smaller scrollbar */
  width: 40%; /* Make the scrollbar width smaller to be centered on the page */
}

.categories-container::-webkit-scrollbar-thumb {
  background: linear-gradient(5deg, #4a44443c, #5e5c5b); /* Gradient thumb */
  border-radius: 10px;
  transition: background 0.3s ease;
}

/* Styling the category cards */
.category-card, .category-car {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px; /* Set a consistent card width */
  flex: 0 0 auto; /* Prevent the card from shrinking */
  padding: 20px;
  scroll-snap-align: center;
  transition: transform 0.3s ease;
}
.category-card, .category-car:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.1);
}

.category-card img, .category-car img {
  width: 40%; /* Default image size */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  display: block;
  margin: 0 auto;
}

.category-card h3, .category-car h3 {
  margin: 15px 0 10px;
  font-size: 1.2rem; /* Updated font size to 1rem */
  color: #333;
}

.category-card p {
  font-size: 10px;
  color: #666;
}

.category-card:hover, .category-car:hover {
  transform: scale(1.05);
}

/* Styling for small screens */
@media (max-width: 768px) {
  .quiz-categories h2 {
    font-size: 2rem;
  }

  .categories-container {
    gap: 10px;
    padding: 10px 20px;
    scroll-padding-left: 20px; /* Ensures first item is visible on small screens */
  }

  .category-card, .category-car {
    width: 180px; /* Reduced card width for small screens */
    padding: 10px; /* Reduced padding for small screens */
  }

  .category-card img, .category-car img {
    width: 60%; /* Adjusted image size to fit smaller cards */
    height: auto; /* Maintain aspect ratio */
  }

  .category-card p, .category-car p {
    font-size: 0.9rem;
  }
}

/* Styling for large screens */
@media (min-width: 1200px) {
  .categories-container {
    justify-content: flex-start; /* Align items to the start */
    gap: 30px;
    padding: 10px 50px;
    scroll-padding-left: 50px; /* Ensures the first item starts fully visible */
    height: 300px; /* Adjust the container height */
  }

  .categories-container::-webkit-scrollbar {
    height: 3px; /* Smaller scrollbar for large screens */
    width: 80%; /* Make the scrollbar width smaller */
    left: 50%;
    transform: translateX(-50%); /* Center the scrollbar horizontally */
    bottom: 10px; /* Position it just above the bottom */
    position: absolute;
  }

  .category-card, .category-car {
    width: 300px; /* Set a consistent card width for large screens */
  }

  .category-card p, .category-car p {
    font-size: 16px;
  }
}


/* Basic styling for the section */
.how-to-play {
  background-color: #f5f5f5c0;
  text-align: center;
  padding-bottom: 60px; /* Adds bottom padding to separate it from other sections and for scrollbar spacing */
  padding-top: 10px;
}

.how-to-play h2 {
  font-size: 2.5rem;
  margin-top:0px;
  margin-bottom: 20px;
  color: #594187;
}

/* Styling the categories container to behave like a carousel */
.play-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  background-color: #ffffff;
  margin-top: 40px;
  gap: 20px;
  padding: 10px 30px; /* Adds padding from the sides */
  height: 300px;
  scroll-padding-left: 10px; /* Ensures the first item starts fully visible */
  position: relative; /* Positioning to center the scrollbar */
}


/* Custom scrollbar for WebKit browsers */
.play-container::-webkit-scrollbar {
  height: 3px; /* Smaller scrollbar */
  width: 40%; /* Make the scrollbar width smaller to be centered on the page */
}

.play-container::-webkit-scrollbar-thumb {
  background: linear-gradient(5deg, #4a44443c, #5e5c5b); /* Gradient thumb */
  border-radius: 10px;
  transition: background 0.3s ease;
}

/* Styling the category cards */
.play-card, .play-car {
  background-color: #ffffffc0;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px; /* Set a consistent card width */
  flex: 0 0 auto; /* Prevent the card from shrinking */
  padding: 20px;
  scroll-snap-align: center;
  transition: transform 0.3s ease;
}

.play-card, .play-car:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.1);
}

.play-card img, .play-car img {
  width: 45%; /* Default image size */
  height: 85%; /* Maintain aspect ratio */
  border-radius: 8px;
  display: block;
  margin: 0 auto;
}

.play-card h3, .play-car h3 {
  margin: 15px 0 10px;
  font-size: 1rem; /* Updated font size to 1rem */
  color: #333;
}

.play-card p, .play-car p {
  font-size: 1rem;
  color: #666;
}

.play-card:hover, .play-car:hover {
  transform: scale(1.05);
}

/* Styling for small screens */
@media (max-width: 768px) {
  .how-to-play h2 {
    font-size: 2rem;
  }

  .play-container {
    gap: 10px;
    padding: 10px 20px;
    scroll-padding-left: 20px; /* Ensures first item is visible on small screens */
  }

  .play-card, .play-car {
    width: 180px; /* Reduced card width for small screens */
    padding: 10px; /* Reduced padding for small screens */
  }

  .play-card img, .play-car img {
    width: 60%; /* Adjusted image size to fit smaller cards */
    height: auto; /* Maintain aspect ratio */
  }

  .play-card p, .play-car p {
    font-size: 0.9rem;
  }
}

/* Styling for large screens */
@media (min-width: 1200px) {
  .play-container {
    justify-content: flex-start; /* Align items to the start */
    gap: 30px;
    padding: 10px 50px;
    scroll-padding-left: 50px; /* Ensures the first item starts fully visible */
    height: 300px; /* Adjust the container height */
  }

  .play-container::-webkit-scrollbar {
    height: 3px; /* Smaller scrollbar for large screens */
    width: 80%; /* Make the scrollbar width smaller */
    left: 50%;
    transform: translateX(-50%); /* Center the scrollbar horizontally */
    bottom: 10px; /* Position it just above the bottom */
    position: absolute;
  }

  .play-card, .play-car {
    width: 300px; /* Set a consistent card width for large screens */
  }

  .play-card p, .play-car p {
    font-size: 1.1rem;
  }
}


/* for the contest section */


/* General styles for all screens */

/* Center the h2 and add margin */
h2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* General styles for all screens */
.contest-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  margin-bottom: 40px;
}

.contest-card {
  background-color: #f5f5f5;
  border-radius: 15px;
  padding: 15px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* On hover, the card will appear more elevated */
.contest-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.1);
}


.contest-header h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contest-difficulty {
  padding: 3px 10px;
  border-radius: 10px;
  font-size: 12px;
  margin: 5px;
  color: white;
}

.contest-difficulty.easy {
  background-color: green;
}

.contest-difficulty.medium {
  background-color: #f0b429;
}

.contest-difficulty.hard {
  background-color: red;
}

/* Styling for the prize pool */
.prize-pool {
  font-size: 25px;
  font-weight: bold;
  color: #594187;
  margin: 5px 0;
}

/* Styling for contest price box */
.contest-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contest-price-box {
  background-color: #594187;
  color: white;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  right: 0;
  text-align: right;
}

/* Download link styling */
.app-download-instruction {
  margin-top: 10px;
  font-size: 14px;
  color: #594187;
  text-align: center;
}

.download-link {
  color: #594187;
  text-decoration: underline;
  cursor: pointer;
}

/* Responsive styles for smaller screens */
@media only screen and (max-width: 768px) {
  .contest-card {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 480px) {
  .contest-card {
    padding: 10px;
  }

  .prize-pool,
  .contest-price-box {
    font-size: 20px;
  }

  .app-download-instruction {
    font-size: 12px;
  }
}



/* Global styles */

/* Global styles */
/* Global container styles */
.ranking-container {
  margin: 20px auto;
  padding: 20px;
  max-width: 1000px;
  background: linear-gradient(135deg, #f0f4f8, #d9e2ec);
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Heading styles */
.ranking-container h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #594187;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

/* Subheadings for columns */
.subheadings {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.05);
}

.subheading-rank, .subheading-earnings {
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  color: #594187;
  text-align: left;
}
.subheading-name{
flex: 5;
  font-size: 18px;
  font-weight: bold;
  color: #594187;
  text-align: left;
}

.subheading-earnings {
  text-align: right;
}

/* Ranking list container */
.ranking-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Individual ranking item styles */
.ranking-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 12px 20px;
  border-radius: 8px;
  border: 1px solid #ecf0f1;
  transition: transform 0.2s, box-shadow 0.2s;
}

.ranking-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* Special styling for top 6 */
.ranking-item.top {
  background: linear-gradient(135deg, #D3C4D6, #D3C4D6);
  border: 2px solid #D3C4D6;
  font-weight: bold;
}

.ranking-item .rank {
  font-size: 20px;
  color: #594187;
  flex: 1;
  text-align: left;
}

.ranking-item .user-name {
  flex: 4;
  color: #2c3e50;
  text-align: left;
}

.ranking-item .total-amount {
  flex: 2;
  color: #594187;
  font-weight: bold;
  text-align: right;
}

/* Scrollable section for remaining users */
.scrollable-section {
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto;
  padding-right: 10px;
}

/* Scrollbar styling */
.scrollable-section::-webkit-scrollbar {
  width: 8px;
}

.scrollable-section::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 4px;
}

.scrollable-section::-webkit-scrollbar-thumb {
  background: #bdc3c7; 
  border-radius: 4px;
}

.scrollable-section::-webkit-scrollbar-thumb:hover {
  background: #95a5a6; 
}

/* Responsive Design */

/* Small screen styles */
@media (max-width: 768px) {
  .ranking-container {
      padding: 15px;
      max-width: 95%;
  }

  .ranking-container h2 {
      font-size: 26px;
  }

  .subheadings {
      padding: 10px 15px;
  }

  .subheading-rank, .subheading-name, .subheading-earnings {
      font-size: 16px;
  }

  .ranking-item {
      padding: 10px 15px;
      font-size: 16px;
  }

  .ranking-item .rank {
      font-size: 18px;
  }

  .ranking-item .total-amount {
      font-size: 16px;
  }
  .subheading-name{
    flex: 4;
      font-size: 18px;
      font-weight: bold;
      color: #594187;
      text-align: left;
    }

  .scrollable-section {
      max-height: 250px; /* Adjust for smaller screens */
  }
}
/* Existing flower classes with varied animation timings */
.flower-1 {
  top: -5px;
  left: -10px;
  animation: float 6s infinite ease-in-out;
}

.flower-2 {
  top: -10px;
  left: -10px;
  animation: float 7s infinite ease-in-out;
}

.flower-3 {
  bottom: -10px;
  left: 15px;
  animation: float 5s infinite ease-in-out;
}

.flower-4 {
  top: 5px;
  right: 20px;
  animation: float 8s infinite ease-in-out;
}

.flower-5 {
  bottom: 10px;
  left: -15px;
  animation: float 6.5s infinite ease-in-out;
}

.flower-6 {
  top: -5px;
  left: -25px;
  animation: float 7.5s infinite ease-in-out;
}
@keyframes float {
    0% {
        transform: translateY(0) rotate(0deg);
    }
    50% {
        transform: translateY(-10px) rotate(360deg);
    }
    100% {
        transform: translateY(0) rotate(720deg);
    }
}
.ad-container {
  margin: 16px 0;
  text-align: center;
}

.adsbygoogle {
  width: 100%;
}
