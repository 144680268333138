
/* Terms Page Container */
.overview-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: #f9f9f9;
  }
  
  /* Header Styling */
  .overview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #594187;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .overview-title {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .overview-nav .overview-nav-list {
    list-style: none;
    display: flex;
    gap: 15px;
  }
  
  .overview-nav .overview-nav-list li {
    display: inline;
  }
  
  .overview-nav .overview-nav-list li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
    position: relative;
    padding: 3px 0;
  }
  
  .overview-nav .overview-nav-list li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #ffffff;
    transition: width 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .overview-nav .overview-nav-list li a:hover::after,
  .overview-nav .overview-nav-list li a.active::after {
    width: 100%;
  }
  
  .overview-nav .overview-nav-list li a:hover,
  .overview-nav .overview-nav-list li a.active {
    color: #e0e0e0;
  }
  
  /* Hamburger Menu */
  .overview-hamburger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
    position: absolute;
    right: 10px;
    z-index: 200;
  }
  
  .overview-hamburger-menu span {
    background-color: #fff;
    height: 2px;
    margin: 4px 0;
    width: 25px;
    transition: 0.3s;
  }
  
  .overview-hamburger-menu.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .overview-hamburger-menu.active span:nth-child(2) {
    opacity: 0;
  }
  
  .overview-hamburger-menu.active span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
  /* Mobile Menu */
  .overview-mobile-menu {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0;
    z-index: 150;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    justify-content: center;
    align-items: center;
  }
  
  .overview-mobile-menu.open {
    display: flex;
    transform: translateX(0);
  }
  
  .overview-mobile-menu a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    transition: color 0.3s ease;
  }
  
  .overview-mobile-menu a.active {
    color: #ddd;
    font-weight: bold;
  }
  
  /* Back Button */
  .overview-back-button {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
  }
  
  .overview-cut-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
  }