/* Full-page container */
.page-container {
  width: 100%;
  min-height: 100vh; /* Allow content to expand vertically */
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  background-color: #4e4e56;

  justify-content: flex-start; /* Align content to the top */
  align-items: center;

  padding: 20px; /* Add padding for better spacing */
  box-sizing: border-box;
}

.page-content {
  display: flex;
  flex-direction: row; /* Ensure horizontal layout */
  width: 100%;
  max-width: 1200px;
  background-color: rgb(159, 210, 219);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px; /* Add gap between left and right sections */
}

/* Left section - Form */
.left-section {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.form-section label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.form-section input, .form-section select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.paynow-button {
  background-color: #28a745;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
}

/* Right section - Product Summary */
.right-section {
  flex: 1;
  padding: 30px;
}

.product-image {
  width: 250px; /* Adjust image size */
  height: 250px;
  object-fit: contain;
  margin-bottom: 15px; /* Add margin for spacing */
}

.price-summary p {
  margin: 5px 0;
}

h2 {
  margin-bottom: 10px;
}

/* Back Button */
.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgb(159, 210, 219);
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

/* Ensure layout responsiveness */
@media (max-width: 768px) {
  .page-content {
    flex-direction: column; /* Stack content vertically on small screens */
    padding: 10px;
  }

  .left-section, .right-section {
    border-right: none; /* Remove the border between sections */
    padding: 10px;
  }

  .product-image {
    width: 100px;
    height: 100px;
  }
}
