/* Home.css */
.mocktest-container {
  font-family: Arial, sans-serif;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.mocketest-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #226880;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mocktest-footer {
  background-color: #226880;
  color: #fff;
  padding: 10px 15px;
  position: relative;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.5s ease-out;
}

.mocktest-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.mocktest-footer-left,
.mocktest-footer-right {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.mocktest-footer-left .mocktest-footer-section,
.mocktest-footer-right .mocktest-footer-section {
  flex: 1;
  min-width: 150px;
}

.mocktest-footer-section p {
  margin-bottom: 8px;
  font-size: 13px;
}

.mocktest-footer-section a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.mocktest-footer-section a:hover {
  color: #ddd;
}

.mocktest-footer-bottom {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 8px;
}

/* Dashboard Container */
.dashboard-container {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 50px;
  background-color: #181818;
  /* Dark theme background */
}

/* Individual Card */
.card {
  background: #242424;
  border-radius: 12px;
  padding: 20px;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-header h3 {
  font-size: 18px;
  margin: 0;
  color: #e0e0e0;
}

.card-header p {
  font-size: 14px;
  color: #a0a0a0;
  margin-top: 5px;
}

.card-body {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 15px 0;
}

.card-body h2 {
  font-size: 24px;
  margin: 0;
}

.card-body .change {
  font-size: 16px;
  font-weight: bold;
}

.card-body .positive {
  color: #4caf50; /* Green for positive changes */
}

.card-body .negative {
  color: #f44336; /* Red for negative changes */
}

.card-chart {
  height: 100px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #aaa;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .dashboard-container {
    grid-template-columns: 1fr;
  }
}

/* Summary Cards Section */
.summary-cards-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: -19px 0;
  padding: 100px 20px;
  background-color: #181818;
  border-radius: 0px;
}

.summary-card {
  background: #f5f5f5c3;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  flex: 1;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.summary-card:hover {
  transform: translateY(-5px);
}

.summary-card h2 {
  font-size: 4rem;
  margin: 10px 0;
  color: #2c3e50;
  animation: pulse 1.5s infinite;
}

.summary-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #594187;
}

.summary-card p {
  font-size: 1rem;
  color: #594187;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.35);
  }
}

/* Responsive Design for Small Screens */
@media (max-width: 768px) {
  .summary-cards-section {
    flex-direction: column;
    gap: 15px;
  }

  .summary-card {
    text-align: left;
  }

  .summary-card h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .summary-card h3 {
    font-size: 1rem;
  }

  .summary-card h2 {
    font-size: 1.5rem;
  }

  .summary-card p {
    font-size: 0.9rem;
  }
}

/* Description Cards Section */
.description-cards-section {
  display: flex;
  background: #181818;
  justify-content: space-between;
  gap: 20px;
  margin: -20px;
  padding: 0px 40px;
}

.description-card {
  background: #ffffffb6;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  flex: 1;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, background 0.3s ease;
}

.description-card:hover {
  transform: translateY(-5px);
  background: #cce7ff;
}

.description-card h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #594187;
}

.description-card ul {
  list-style: disc;
  padding-left: 10px;
  margin: 10px 0;
}

.description-card li {
  font-size: 1.1rem;
  line-height: 1;
  color: #34495e;
  margin-bottom: 10px;
  text-align: left;
}

.description-card p {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #34495e;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .description-cards-section {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 10px;
  }

  .description-card {
    width: 90%;
    padding: 15px;
  }

  .description-card h3 {
    font-size: 1.5rem;
  }

  .description-card p {
    font-size: 1rem;
  }
}

.graph-section {
  margin-top: 0;
  padding: 2rem;
  background-color: #181818;
  border-radius: 0px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.graph-section h2 {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #594187;
}

.cards-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chart-container {
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  margin: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  flex: 1;
  max-width: calc(33.333% - 2rem);
}

.chart-container h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #555;
}

.chart-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.fade-in {
  animation: fadeIn 0.8s ease-in-out;
}

.slide-up {
  animation: slideUp 0.8s ease-in-out;
}

.zoom-in {
  animation: zoomIn 0.8s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Layout for Large Screens */
.cards-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chart-container {
  flex: 1;
  margin: 1rem;
  max-width: calc(33.333% - 2rem);
}

/* Layout for Small Screens */
@media (max-width: 768px) {
  .cards-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .chart-container {
    max-width: 100%;
  }
}
